<template>
  <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRKhrKL8Sb082PSA4vqTKjK-LdCBUjhI5ytu5_RBN1agTi55pDVPTKr71f0J_SMDi-lMQtzgHgtFVtJ/pubhtml?gid=82164507&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
</template>

<script>
export default {
  name: 'Archive'
}
</script>

<style scoped>
iframe {
  width: 100%;
  min-height: 90vh;
}

</style>
